
































































import moment from 'moment';
import { computed, defineComponent, ref, PropType } from '@vue/composition-api';

import { MONITOR_HEADER } from '@/constants/monitor';

export default defineComponent({
  name: 'TableView',
  props: {
    selectedStudent: {
      required: true,
      type: Object
    },
    studentAdks: {
      required: true,
      type: Array,
      default: () => []
    },
    activityFilter: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const filteredActivities = computed(() => {
      const adkListToLookIn = props.studentAdks?.filter(a => a.status);
      const studentData = adkListToLookIn.map((studentAdk, index) => {
        return {
          index: index + 1,
          activity: studentAdk?.name,
          unlocked: !!studentAdk?.completed,
          completion:
            (studentAdk && (studentAdk.completed || studentAdk.completed)) ||
            studentAdk?.name === 'contact' ||
            false,
          completedTime:
            (studentAdk && studentAdk.completed) || studentAdk?.name === 'contact' || ''
        };
      });
      if (!props.activityFilter) return studentData;
      const index =
        studentData.findIndex(
          item => item.activity.toLowerCase() === props.activityFilter.toLowerCase()
        ) + 1;
      return studentData.slice(0, index >= 0 ? index : studentData.length - 1);
    });

    const isOfferCompleted = computed(() => {
      const index = props.studentAdks?.findIndex(a => a.name.toLowerCase() === 'offer');
      return props.studentAdks[index]?.offerStatus;
    });

    const lastUnlockedIndex = computed(() => {
      let lastUnlockedIndex = 0;
      props.studentAdks.forEach((programAdk, index) => {
        const studentAdk = props.studentAdks.find(
          adk => adk.name && adk.name.toLowerCase() === programAdk
        );
        if (studentAdk !== undefined) lastUnlockedIndex = index;
      });
      return lastUnlockedIndex;
    });

    const formatDate = (date: Date) => {
      return moment(date).fromNow();
    };

    const handleMonitorButtonClick = item => {
      context.emit('monitor-click', {
        page: item.index - 1
      });
    };

    return {
      header: ref(MONITOR_HEADER),
      filteredActivities,
      lastUnlockedIndex,
      formatDate,
      handleMonitorButtonClick,
      isOfferCompleted
    };
  }
});
